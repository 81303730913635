const TypeChart = [
    /*bug*/
    [2,4,2,2,1,1,1,1,1,4,2,2,2,1,4,2,1,2],
    /*dark*/
    [2,1,2,2,1,1,2,2,4,2,2,2,2,2,4,2,2,2],
    /*dragon*/
    [2,2,4,2,0,2,2,2,2,2,2,2,2,2,2,2,1,2],
    /*electric*/
    [2,2,1,1,2,2,2,4,2,1,0,2,2,2,2,2,2,4],
    /*fairy*/
    [2,4,4,2,2,4,1,2,2,2,2,2,2,1,2,2,1,2],
    /*fighting*/
    [1,4,2,2,1,2,2,1,0,2,2,4,4,1,1,4,4,2],
    /*fire*/
    [4,2,1,2,2,2,1,2,2,4,2,4,2,2,2,1,4,1],
    /*flying*/
    [4,2,2,1,2,4,2,2,2,4,2,2,2,2,2,1,1,2],
    /*ghost*/
    [2,1,2,2,2,2,2,2,4,2,2,2,0,2,4,2,2,2],
    /*grass*/
    [1,2,1,2,2,2,1,1,2,1,4,2,2,1,2,4,1,4],
    /*ground*/
    [1,2,2,4,2,2,4,0,2,1,2,2,2,4,2,4,4,2],
    /*ice*/
    [2,2,4,2,2,2,1,4,2,4,4,1,2,2,2,2,1,1],
    /*normal*/
    [2,2,2,2,2,2,2,2,0,2,2,2,2,2,2,1,1,2],
    /*poison*/
    [2,2,2,2,4,2,2,2,1,4,1,2,2,1,2,1,0,2],
    /*psychic*/
    [2,0,2,2,2,4,2,2,2,2,2,2,2,4,1,2,1,2],
    /*rock*/
    [4,2,2,2,2,1,4,4,2,2,1,4,2,2,2,2,1,2],
    /*steel*/
    [2,2,2,1,4,2,1,2,2,2,2,4,2,2,2,4,1,1],
    /*water*/
    [2,2,1,2,2,2,4,2,2,1,4,2,2,2,2,4,2,1]
];

export default TypeChart;