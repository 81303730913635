const PokemonList = [
	"Bulbasaur",
	"Ivysaur",
	"Venusaur",
	"Charmander",
	"Charmeleon",
	"Charizard",
	"Squirtle",
	"Wartortle",
	"Blastoise",
	"Caterpie",
	"Metapod",
	"Butterfree",
	"Weedle",
	"Kakuna",
	"Beedrill",
	"Pidgey",
	"Pidgeotto",
	"Pidgeot",
	"Rattata",
	"Raticate",
	"Spearow",
	"Fearow",
	"Ekans",
	"Arbok",
	"Pikachu",
	"Raichu",
	"Sandshrew",
	"Sandslash",
	"Nidoran♀",
	"Nidorina",
	"Nidoqueen",
	"Nidoran♂",
	"Nidorino",
	"Nidoking",
	"Clefairy",
	"Clefable",
	"Vulpix",
	"Ninetales",
	"Jigglypuff",
	"Wigglytuff",
	"Zubat",
	"Golbat",
	"Oddish",
	"Gloom",
	"Vileplume",
	"Paras",
	"Parasect",
	"Venonat",
	"Venomoth",
	"Diglett",
	"Dugtrio",
	"Meowth",
	"Persian",
	"Psyduck",
	"Golduck",
	"Mankey",
	"Primeape",
	"Growlithe",
	"Arcanine",
	"Poliwag",
	"Poliwhirl",
	"Poliwrath",
	"Abra",
	"Kadabra",
	"Alakazam",
	"Machop",
	"Machoke",
	"Machamp",
	"Bellsprout",
	"Weepinbell",
	"Victreebel",
	"Tentacool",
	"Tentacruel",
	"Geodude",
	"Graveler",
	"Golem",
	"Ponyta",
	"Rapidash",
	"Slowpoke",
	"Slowbro",
	"Magnemite",
	"Magneton",
	"Farfetchd",
	"Doduo",
	"Dodrio",
	"Seel",
	"Dewgong",
	"Grimer",
	"Muk",
	"Shellder",
	"Cloyster",
	"Gastly",
	"Haunter",
	"Gengar",
	"Onix",
	"Drowzee",
	"Hypno",
	"Krabby",
	"Kingler",
	"Voltorb",
	"Electrode",
	"Exeggcute",
	"Exeggutor",
	"Cubone",
	"Marowak",
	"Hitmonlee",
	"Hitmonchan",
	"Lickitung",
	"Koffing",
	"Weezing",
	"Rhyhorn",
	"Rhydon",
	"Chansey",
	"Tangela",
	"Kangaskhan",
	"Horsea",
	"Seadra",
	"Goldeen",
	"Seaking",
	"Staryu",
	"Starmie",
	"Mr-Mime",
	"Scyther",
	"Jynx",
	"Electabuzz",
	"Magmar",
	"Pinsir",
	"Tauros",
	"Magikarp",
	"Gyarados",
	"Lapras",
	"Ditto",
	"Eevee",
	"Vaporeon",
	"Jolteon",
	"Flareon",
	"Porygon",
	"Omanyte",
	"Omastar",
	"Kabuto",
	"Kabutops",
	"Aerodactyl",
	"Snorlax",
	"Articuno",
	"Zapdos",
	"Moltres",
	"Dratini",
	"Dragonair",
	"Dragonite",
	"Mewtwo",
	"Mew",
	"Chikorita",
	"Bayleef",
	"Meganium",
	"Cyndaquil",
	"Quilava",
	"Typhlosion",
	"Totodile",
	"Croconaw",
	"Feraligatr",
	"Sentret",
	"Furret",
	"Hoothoot",
	"Noctowl",
	"Ledyba",
	"Ledian",
	"Spinarak",
	"Ariados",
	"Crobat",
	"Chinchou",
	"Lanturn",
	"Pichu",
	"Cleffa",
	"Igglybuff",
	"Togepi",
	"Togetic",
	"Natu",
	"Xatu",
	"Mareep",
	"Flaaffy",
	"Ampharos",
	"Bellossom",
	"Marill",
	"Azumarill",
	"Sudowoodo",
	"Politoed",
	"Hoppip",
	"Skiploom",
	"Jumpluff",
	"Aipom",
	"Sunkern",
	"Sunflora",
	"Yanma",
	"Wooper",
	"Quagsire",
	"Espeon",
	"Umbreon",
	"Murkrow",
	"Slowking",
	"Misdreavus",
	"Unown",
	"Wobbuffet",
	"Girafarig",
	"Pineco",
	"Forretress",
	"Dunsparce",
	"Gligar",
	"Steelix",
	"Snubbull",
	"Granbull",
	"Qwilfish",
	"Scizor",
	"Shuckle",
	"Heracross",
	"Sneasel",
	"Teddiursa",
	"Ursaring",
	"Slugma",
	"Magcargo",
	"Swinub",
	"Piloswine",
	"Corsola",
	"Remoraid",
	"Octillery",
	"Delibird",
	"Mantine",
	"Skarmory",
	"Houndour",
	"Houndoom",
	"Kingdra",
	"Phanpy",
	"Donphan",
	"Porygon2",
	"Stantler",
	"Smeargle",
	"Tyrogue",
	"Hitmontop",
	"Smoochum",
	"Elekid",
	"Magby",
	"Miltank",
	"Blissey",
	"Raikou",
	"Entei",
	"Suicune",
	"Larvitar",
	"Pupitar",
	"Tyranitar",
	"Lugia",
	"Ho-Oh",
	"Celebi",
	"Treecko",
	"Grovyle",
	"Sceptile",
	"Torchic",
	"Combusken",
	"Blaziken",
	"Mudkip",
	"Marshtomp",
	"Swampert",
	"Poochyena",
	"Mightyena",
	"Zigzagoon",
	"Linoone",
	"Wurmple",
	"Silcoon",
	"Beautifly",
	"Cascoon",
	"Dustox",
	"Lotad",
	"Lombre",
	"Ludicolo",
	"Seedot",
	"Nuzleaf",
	"Shiftry",
	"Taillow",
	"Swellow",
	"Wingull",
	"Pelipper",
	"Ralts",
	"Kirlia",
	"Gardevoir",
	"Surskit",
	"Masquerain",
	"Shroomish",
	"Breloom",
	"Slakoth",
	"Vigoroth",
	"Slaking",
	"Nincada",
	"Ninjask",
	"Shedinja",
	"Whismur",
	"Loudred",
	"Exploud",
	"Makuhita",
	"Hariyama",
	"Azurill",
	"Nosepass",
	"Skitty",
	"Delcatty",
	"Sableye",
	"Mawile",
	"Aron",
	"Lairon",
	"Aggron",
	"Meditite",
	"Medicham",
	"Electrike",
	"Manectric",
	"Plusle",
	"Minun",
	"Volbeat",
	"Illumise",
	"Roselia",
	"Gulpin",
	"Swalot",
	"Carvanha",
	"Sharpedo",
	"Wailmer",
	"Wailord",
	"Numel",
	"Camerupt",
	"Torkoal",
	"Spoink",
	"Grumpig",
	"Spinda",
	"Trapinch",
	"Vibrava",
	"Flygon",
	"Cacnea",
	"Cacturne",
	"Swablu",
	"Altaria",
	"Zangoose",
	"Seviper",
	"Lunatone",
	"Solrock",
	"Barboach",
	"Whiscash",
	"Corphish",
	"Crawdaunt",
	"Baltoy",
	"Claydol",
	"Lileep",
	"Cradily",
	"Anorith",
	"Armaldo",
	"Feebas",
	"Milotic",
	"Castform",
	"Kecleon",
	"Shuppet",
	"Banette",
	"Duskull",
	"Dusclops",
	"Tropius",
	"Chimecho",
	"Absol",
	"Wynaut",
	"Snorunt",
	"Glalie",
	"Spheal",
	"Sealeo",
	"Walrein",
	"Clamperl",
	"Huntail",
	"Gorebyss",
	"Relicanth",
	"Luvdisc",
	"Bagon",
	"Shelgon",
	"Salamence",
	"Beldum",
	"Metang",
	"Metagross",
	"Regirock",
	"Regice",
	"Registeel",
	"Latias",
	"Latios",
	"Kyogre",
	"Kyogre-primal",
	"Groudon",
	"Groudon-primal",
	"Rayquaza",
	"Jirachi",
	"Deoxys-normal",
	"Deoxys-attack",
	"Deoxys-defense",
	"Deoxys-speed",
	"Turtwig",
	"Grotle",
	"Torterra",
	"Chimchar",
	"Monferno",
	"Infernape",
	"Piplup",
	"Prinplup",
	"Empoleon",
	"Starly",
	"Staravia",
	"Staraptor",
	"Bidoof",
	"Bibarel",
	"Kricketot",
	"Kricketune",
	"Shinx",
	"Luxio",
	"Luxray",
	"Budew",
	"Roserade",
	"Cranidos",
	"Rampardos",
	"Shieldon",
	"Bastiodon",
	"Burmy",
	"Wormadam-plant",
	"Wormadam-sandy",
	"Wormadam-trash",
	"Mothim",
	"Combee",
	"Vespiquen",
	"Pachirisu",
	"Buizel",
	"Floatzel",
	"Cherubi",
	"Cherrim",
	"Shellos",
	"Gastrodon",
	"Ambipom",
	"Drifloon",
	"Drifblim",
	"Buneary",
	"Lopunny",
	"Mismagius",
	"Honchkrow",
	"Glameow",
	"Purugly",
	"Chingling",
	"Stunky",
	"Skuntank",
	"Bronzor",
	"Bronzong",
	"Bonsly",
	"Mime-Jr",
	"Happiny",
	"Chatot",
	"Spiritomb",
	"Gible",
	"Gabite",
	"Garchomp",
	"Munchlax",
	"Riolu",
	"Lucario",
	"Hippopotas",
	"Hippowdon",
	"Skorupi",
	"Drapion",
	"Croagunk",
	"Toxicroak",
	"Carnivine",
	"Finneon",
	"Lumineon",
	"Mantyke",
	"Snover",
	"Abomasnow",
	"Weavile",
	"Magnezone",
	"Lickilicky",
	"Rhyperior",
	"Tangrowth",
	"Electivire",
	"Magmortar",
	"Togekiss",
	"Yanmega",
	"Leafeon",
	"Glaceon",
	"Gliscor",
	"Mamoswine",
	"Porygon-Z",
	"Gallade",
	"Probopass",
	"Dusknoir",
	"Froslass",
	"Rotom",
	"Rotom-heat",
	"Rotom-wash",
	"Rotom-frost",
	"Rotom-fan",
	"Rotom-mow",
	"Uxie",
	"Mesprit",
	"Azelf",
	"Dialga",
	"Palkia",
	"Heatran",
	"Regigigas",
	"Giratina-altered",
	"Giratina-origin",
	"Cresselia",
	"Phione",
	"Manaphy",
	"Darkrai",
	"Shaymin-land",
	"Shaymin-sky",
	"Arceus",
	"Victini",
	"Snivy",
	"Servine",
	"Serperior",
	"Tepig",
	"Pignite",
	"Emboar",
	"Oshawott",
	"Dewott",
	"Samurott",
	"Patrat",
	"Watchog",
	"Lillipup",
	"Herdier",
	"Stoutland",
	"Purrloin",
	"Liepard",
	"Pansage",
	"Simisage",
	"Pansear",
	"Simisear",
	"Panpour",
	"Simipour",
	"Munna",
	"Musharna",
	"Pidove",
	"Tranquill",
	"Unfezant",
	"Blitzle",
	"Zebstrika",
	"Roggenrola",
	"Boldore",
	"Gigalith",
	"Woobat",
	"Swoobat",
	"Drilbur",
	"Excadrill",
	"Audino",
	"Timburr",
	"Gurdurr",
	"Conkeldurr",
	"Tympole",
	"Palpitoad",
	"Seismitoad",
	"Throh",
	"Sawk",
	"Sewaddle",
	"Swadloon",
	"Leavanny",
	"Venipede",
	"Whirlipede",
	"Scolipede",
	"Cottonee",
	"Whimsicott",
	"Petilil",
	"Lilligant",
	"basculin-blue-striped",
	"basculin-red-striped",
	"Sandile",
	"Krokorok",
	"Krookodile",
	"Darumaka",
	"Darmanitan-standard",
	"Darmanitan-zen",
	"Maractus",
	"Dwebble",
	"Crustle",
	"Scraggy",
	"Scrafty",
	"Sigilyph",
	"Yamask",
	"Cofagrigus",
	"Tirtouga",
	"Carracosta",
	"Archen",
	"Archeops",
	"Trubbish",
	"Garbodor",
	"Zorua",
	"Zoroark",
	"Minccino",
	"Cinccino",
	"Gothita",
	"Gothorita",
	"Gothitelle",
	"Solosis",
	"Duosion",
	"Reuniclus",
	"Ducklett",
	"Swanna",
	"Vanillite",
	"Vanillish",
	"Vanilluxe",
	"Deerling",
	"Sawsbuck",
	"Emolga",
	"Karrablast",
	"Escavalier",
	"Foongus",
	"Amoonguss",
	"Frillish",
	"Jellicent",
	"Alomomola",
	"Joltik",
	"Galvantula",
	"Ferroseed",
	"Ferrothorn",
	"Klink",
	"Klang",
	"Klinklang",
	"Tynamo",
	"Eelektrik",
	"Eelektross",
	"Elgyem",
	"Beheeyem",
	"Litwick",
	"Lampent",
	"Chandelure",
	"Axew",
	"Fraxure",
	"Haxorus",
	"Cubchoo",
	"Beartic",
	"Cryogonal",
	"Shelmet",
	"Accelgor",
	"Stunfisk",
	"Mienfoo",
	"Mienshao",
	"Druddigon",
	"Golett",
	"Golurk",
	"Pawniard",
	"Bisharp",
	"Bouffalant",
	"Rufflet",
	"Braviary",
	"Vullaby",
	"Mandibuzz",
	"Heatmor",
	"Durant",
	"Deino",
	"Zweilous",
	"Hydreigon",
	"Larvesta",
	"Volcarona",
	"Cobalion",
	"Terrakion",
	"Virizion",
	"Tornadus-incarnate",
	"Tornadus-therian",
	"Thundurus-incarnate",
	"Thundurus-therian",
	"Reshiram",
	"Zekrom",
	"Landorus-incarnate",
	"Landorus-therian",
	"Kyurem",
	"Kyurem-white",
	"Kyurem-black",
	"Keldeo-ordinary",
	"Keldeo-resolute",
	"Meloetta-aria",
	"Meloetta-pirouette",
	"Genesect",
	"Chespin",
	"Quilladin",
	"Chesnaught",
	"Fennekin",
	"Braixen",
	"Delphox",
	"Froakie",
	"Frogadier",
	"Greninja",
	"Greninja-ash",
	"Bunnelby",
	"Diggersby",
	"Fletchling",
	"Fletchinder",
	"Talonflame",
	"Scatterbug",
	"Spewpa",
	"Vivillon",
	"Litleo",
	"Pyroar",
	"Flabebe",
	"Floette",
	"Florges",
	"Skiddo",
	"Gogoat",
	"Pancham",
	"Pangoro",
	"Furfrou",
	"Espurr",
	"Meowstic",
	"Honedge",
	"Doublade",
	"Aegislash-shield",
	"Aegislash-blade",
	"Spritzee",
	"Aromatisse",
	"Swirlix",
	"Slurpuff",
	"Inkay",
	"Malamar",
	"Binacle",
	"Barbaracle",
	"Skrelp",
	"Dragalge",
	"Clauncher",
	"Clawitzer",
	"Helioptile",
	"Heliolisk",
	"Tyrunt",
	"Tyrantrum",
	"Amaura",
	"Aurorus",
	"Sylveon",
	"Hawlucha",
	"Dedenne",
	"Carbink",
	"Goomy",
	"Sliggoo",
	"Goodra",
	"Klefki",
	"Phantump",
	"Trevenant",
	"Pumpkaboo",
	"Gourgeist",
	"Bergmite",
	"Avalugg",
	"Noibat",
	"Noivern",
	"Xerneas",
	"Yveltal",
	"Zygarde",
	"Diancie",
	"Hoopa",
	"Hoopa-unbound",
	"Volcanion",
	"Rowlet",
	"Dartrix",
	"Decidueye",
	"Litten",
	"Torracat",
	"Incineroar",
	"Popplio",
	"Brionne",
	"Primarina",
	"Pikipek",
	"Trumbeak",
	"Toucannon",
	"Yungoos",
	"Gumshoos",
	"Grubbin",
	"Charjabug",
	"Vikavolt",
	"Crabrawler",
	"Crabominable",
	"Oricorio-baile",
	"Oricorio-pom-pom",
	"Oricorio-pau",
	"Oricorio-sensu",
	"Cutiefly",
	"Ribombee",
	"Rockruff",
	"Lycanroc-midday",
	"Lycanroc-midnight",
	"Lycanroc",
	"Wishiwashi-solo",
	"Wishiwashi-school",
	"Mareanie",
	"Toxapex",
	"Mudbray",
	"Mudsdale",
	"Dewpider",
	"Araquanid",
	"Fomantis",
	"Lurantis",
	"Morelull",
	"Shiinotic",
	"Salandit",
	"Salazzle",
	"Stufful",
	"Bewear",
	"Bounsweet",
	"Steenee",
	"Tsareena",
	"Comfey",
	"Oranguru",
	"Passimian",
	"Wimpod",
	"Golisopod",
	"Sandygast",
	"Palossand",
	"Pyukumuku",
	"Type-Null",
	"Silvally",
	"Minior-red",
	"Minior-red-meteor",
	"Komala",
	"Turtonator",
	"Togedemaru",
	"Mimikyu-disguised",
	"Mimikyu-busted",
	"Bruxish",
	"Drampa",
	"Dhelmise",
	"Jangmo-o",
	"Hakamo-o",
	"Kommo-o",
	"Tapu-Koko",
	"Tapu-Lele",
	"Tapu-Bulu",
	"Tapu-Fini",
	"Cosmog",
	"Cosmoem",
	"Solgaleo",
	"Lunala",
	"Nihilego",
	"Buzzwole",
	"Pheromosa",
	"Xurkitree",
	"Celesteela",
	"Kartana",
	"Guzzlord",
	"Necrozma",
	"Necrozma-dusk",
	"Necrozma-dawn",
	"Necrozma-ultra",
	"Magearna",
	"Marshadow",
	"Poipole",
	"Naganadel",
	"Stakataka",
	"Blacephalon",
	"Zeraora",
	"Meltan",
	"Melmetal",
	"Grookey",
	"Thwackey",
	"Rillaboom",
	"Scorbunny",
	"Raboot",
	"Cinderace",
	"Sobble",
	"Drizzile",
	"Inteleon",
	"Skwovet",
	"Greedent",
	"Rookidee",
	"Corvisquire",
	"Corviknight",
	"Blipbug",
	"Dottler",
	"Orbeetle",
	"Nickit",
	"Thievul",
	"Gossifleur",
	"Eldegoss",
	"Wooloo",
	"Dubwool",
	"Chewtle",
	"Drednaw",
	"Yamper",
	"Boltund",
	"Rolycoly",
	"Carkol",
	"Coalossal",
	"Applin",
	"Flapple",
	"Appletun",
	"Silicobra",
	"Sandaconda",
	"Cramorant",
	"Arrokuda",
	"Barraskewda",
	"Toxel",
	"Toxtricity-amped",
	"Toxtricity-low-key",
	"Sizzlipede",
	"Centiskorch",
	"Clobbopus",
	"Grapploct",
	"Sinistea",
	"Polteageist",
	"Hatenna",
	"Hattrem",
	"Hatterene",
	"Impidimp",
	"Morgrem",
	"Grimmsnarl",
	"Obstagoon",
	"Perrserker",
	"Cursola",
	"Sirfetchd",
	"Mr-Rime",
	"Runerigus",
	"Milcery",
	"Alcremie",
	"Falinks",
	"Pincurchin",
	"Snom",
	"Frosmoth",
	"Stonjourner",
	"Eiscue-ice",
	"Eiscue-noice",
	"Indeedee",
	"Morpeko",
	"Cufant",
	"Copperajah",
	"Dracozolt",
	"Arctozolt",
	"Dracovish",
	"Arctovish",
	"Duraludon",
	"Dreepy",
	"Drakloak",
	"Dragapult",
	"Zacian-hero",
	"Zacian-crowned",
	"Zamazenta-hero",
	"Zamazenta-crowned",
	"Eternatus",
	"Eternatus-eternamax",
	//Regional variants:
	//Alola:
	"rattata-alola",
	"raticate-alola",
	"raichu-alola",
	"sandshrew-alola",
	"sandslash-alola",
	"vulpix-alola",
	"ninetales-alola",
	"diglett-alola",
	"dugtrio-alola",
	"meowth-alola",
	"persian-alola",
	"geodude-alola",
	"graveler-alola",
	"golem-alola",
	"grimer-alola",
	"muk-alola",
	"exeggutor-alola",
	"marowak-alola",
	//galar
	"meowth-galar",
	"ponyta-galar",
	"rapidash-galar",
	"slowpoke-galar",
	"slowbro-galar",
	"slowking-galar",
	"farfetchd-galar",
	"weezing-galar",
	"mr-mime-galar",
	"articuno-galar",
	"zapdos-galar",
	"moltres-galar",
	"corsola-galar",
	"zigzagoon-galar",
	"linoone-galar",
	"darumaka-galar",
	"darmanitan-standard-galar",
	"darmanitan-zen-galar",
	"yamask-galar",
	"stunfisk-galar",
	//mega-evolutions:
	//X and Y
	"venusaur-mega",
	"charizard-mega-x",
	"charizard-mega-y",
	"blastoise-mega",
	"alakazam-mega",
	"gengar-mega",
	"kangaskhan-mega",
	"pinsir-mega",
	"gyarados-mega",
	"aerodactyl-mega",
	"mewtwo-mega-x",
	"ampharos-mega",
	"scizor-mega",
	"heracross-mega",
	"houndoom-mega",
	"tyranitar-mega",
	"blaziken-mega",
	"gardevoir-mega",
	"mawile-mega",
	"aggron-mega",
	"medicham-mega",
	"manectric-mega",
	"banette-mega",
	"absol-mega",
	"latias-mega",
	"latios-mega",
	"garchomp-mega",
	"lucario-mega",
	"abomasnow-mega",
	//Omega Ruby and Alpha Sapphire
	"beedrill-mega",
	"pidgeot-mega",
	"slowbro-mega",
	"steelix-mega",
	"sceptile-mega",
	"swampert-mega",
	"sableye-mega",
	"sharpedo-mega",
	"camerupt-mega",
	"altaria-mega",
	"glalie-mega",
	"salamence-mega",
	"metagross-mega",
	"rayquaza-mega",
	"lopunny-mega",
	"gallade-mega",
	"audino-mega",
	"diancie-mega",
];

export default PokemonList;