const MoveList = [
    "pound",
    "karate-chop",
    "double-slap",
    "comet-punch",
    "mega-punch",
    "pay-day",
    "fire-punch",
    "ice-punch",
    "thunder-punch",
    "scratch",
    "vice-grip",
    "guillotine",
    "razor-wind",
    "swords-dance",
    "cut",
    "gust",
    "wing-attack",
    "whirlwind",
    "fly",
    "bind",
    "slam",
    "vine-whip",
    "stomp",
    "double-kick",
    "mega-kick",
    "jump-kick",
    "rolling-kick",
    "sand-attack",
    "headbutt",
    "horn-attack",
    "fury-attack",
    "horn-drill",
    "tackle",
    "body-slam",
    "wrap",
    "take-down",
    "thrash",
    "double-edge",
    "tail-whip",
    "poison-sting",
    "twineedle",
    "pin-missile",
    "leer",
    "bite",
    "growl",
    "roar",
    "sing",
    "supersonic",
    "sonic-boom",
    "disable",
    "acid",
    "ember",
    "flamethrower",
    "mist",
    "water-gun",
    "hydro-pump",
    "surf",
    "ice-beam",
    "blizzard",
    "psybeam",
    "bubble-beam",
    "aurora-beam",
    "hyper-beam",
    "peck",
    "drill-peck",
    "submission",
    "low-kick",
    "counter",
    "seismic-toss",
    "strength",
    "absorb",
    "mega-drain",
    "leech-seed",
    "growth",
    "razor-leaf",
    "solar-beam",
    "poison-powder",
    "stun-spore",
    "sleep-powder",
    "petal-dance",
    "string-shot",
    "dragon-rage",
    "fire-spin",
    "thunder-shock",
    "thunderbolt",
    "thunder-wave",
    "thunder",
    "rock-throw",
    "earthquake",
    "fissure",
    "dig",
    "toxic",
    "confusion",
    "psychic",
    "hypnosis",
    "meditate",
    "agility",
    "quick-attack",
    "rage",
    "teleport",
    "night-shade",
    "mimic",
    "screech",
    "double-team",
    "recover",
    "harden",
    "minimize",
    "smokescreen",
    "confuse-ray",
    "withdraw",
    "defense-curl",
    "barrier",
    "light-screen",
    "haze",
    "reflect",
    "focus-energy",
    "bide",
    "metronome",
    "mirror-move",
    "self-destruct",
    "egg-bomb",
    "lick",
    "smog",
    "sludge",
    "bone-club",
    "fire-blast",
    "waterfall",
    "clamp",
    "swift",
    "skull-bash",
    "spike-cannon",
    "constrict",
    "amnesia",
    "kinesis",
    "soft-boiled",
    "high-jump-kick",
    "glare",
    "dream-eater",
    "poison-gas",
    "barrage",
    "leech-life",
    "lovely-kiss",
    "sky-attack",
    "transform",
    "bubble",
    "dizzy-punch",
    "spore",
    "flash",
    "psywave",
    "splash",
    "acid-armor",
    "crabhammer",
    "explosion",
    "fury-swipes",
    "bonemerang",
    "rest",
    "rock-slide",
    "hyper-fang",
    "sharpen",
    "conversion",
    "tri-attack",
    "super-fang",
    "slash",
    "substitute",
    "struggle",
    "sketch",
    "triple-kick",
    "thief",
    "spider-web",
    "mind-reader",
    "nightmare",
    "flame-wheel",
    "snore",
    "curse",
    "flail",
    "conversion-2",
    "aeroblast",
    "cotton-spore",
    "reversal",
    "spite",
    "powder-snow",
    "protect",
    "mach-punch",
    "scary-face",
    "feint-attack",
    "sweet-kiss",
    "belly-drum",
    "sludge-bomb",
    "mud-slap",
    "octazooka",
    "spikes",
    "zap-cannon",
    "foresight",
    "destiny-bond",
    "perish-song",
    "icy-wind",
    "detect",
    "bone-rush",
    "lock-on",
    "outrage",
    "sandstorm",
    "giga-drain",
    "endure",
    "charm",
    "rollout",
    "false-swipe",
    "swagger",
    "milk-drink",
    "spark",
    "fury-cutter",
    "steel-wing",
    "mean-look",
    "attract",
    "sleep-talk",
    "heal-bell",
    "return",
    "present",
    "frustration",
    "safeguard",
    "pain-split",
    "sacred-fire",
    "magnitude",
    "dynamic-punch",
    "megahorn",
    "dragon-breath",
    "baton-pass",
    "encore",
    "pursuit",
    "rapid-spin",
    "sweet-scent",
    "iron-tail",
    "metal-claw",
    "vital-throw",
    "morning-sun",
    "synthesis",
    "moonlight",
    "hidden-power",
    "cross-chop",
    "twister",
    "rain-dance",
    "sunny-day",
    "crunch",
    "mirror-coat",
    "psych-up",
    "extreme-speed",
    "ancient-power",
    "shadow-ball",
    "future-sight",
    "rock-smash",
    "whirlpool",
    "beat-up",
    "fake-out",
    "uproar",
    "stockpile",
    "spit-up",
    "swallow",
    "heat-wave",
    "hail",
    "torment",
    "flatter",
    "will-o-wisp",
    "memento",
    "facade",
    "focus-punch",
    "smelling-salts",
    "follow-me",
    "nature-power",
    "charge",
    "taunt",
    "helping-hand",
    "trick",
    "role-play",
    "wish",
    "assist",
    "ingrain",
    "superpower",
    "magic-coat",
    "recycle",
    "revenge",
    "brick-break",
    "yawn",
    "knock-off",
    "endeavor",
    "eruption",
    "skill-swap",
    "imprison",
    "refresh",
    "grudge",
    "snatch",
    "secret-power",
    "dive",
    "arm-thrust",
    "camouflage",
    "tail-glow",
    "luster-purge",
    "mist-ball",
    "feather-dance",
    "teeter-dance",
    "blaze-kick",
    "mud-sport",
    "ice-ball",
    "needle-arm",
    "slack-off",
    "hyper-voice",
    "poison-fang",
    "crush-claw",
    "blast-burn",
    "hydro-cannon",
    "meteor-mash",
    "astonish",
    "weather-ball",
    "aromatherapy",
    "fake-tears",
    "air-cutter",
    "overheat",
    "odor-sleuth",
    "rock-tomb",
    "silver-wind",
    "metal-sound",
    "grass-whistle",
    "tickle",
    "cosmic-power",
    "water-spout",
    "signal-beam",
    "shadow-punch",
    "extrasensory",
    "sky-uppercut",
    "sand-tomb",
    "sheer-cold",
    "muddy-water",
    "bullet-seed",
    "aerial-ace",
    "icicle-spear",
    "iron-defense",
    "block",
    "howl",
    "dragon-claw",
    "frenzy-plant",
    "bulk-up",
    "bounce",
    "mud-shot",
    "poison-tail",
    "covet",
    "volt-tackle",
    "magical-leaf",
    "water-sport",
    "calm-mind",
    "leaf-blade",
    "dragon-dance",
    "rock-blast",
    "shock-wave",
    "water-pulse",
    "doom-desire",
    "psycho-boost",
    "roost",
    "gravity",
    "miracle-eye",
    "wake-up-slap",
    "hammer-arm",
    "gyro-ball",
    "healing-wish",
    "brine",
    "natural-gift",
    "feint",
    "pluck",
    "tailwind",
    "acupressure",
    "metal-burst",
    "u-turn",
    "close-combat",
    "payback",
    "assurance",
    "embargo",
    "fling",
    "psycho-shift",
    "trump-card",
    "heal-block",
    "wring-out",
    "power-trick",
    "gastro-acid",
    "lucky-chant",
    "me-first",
    "copycat",
    "power-swap",
    "guard-swap",
    "punishment",
    "last-resort",
    "worry-seed",
    "sucker-punch",
    "toxic-spikes",
    "heart-swap",
    "aqua-ring",
    "magnet-rise",
    "flare-blitz",
    "force-palm",
    "aura-sphere",
    "rock-polish",
    "poison-jab",
    "dark-pulse",
    "night-slash",
    "aqua-tail",
    "seed-bomb",
    "air-slash",
    "x-scissor",
    "bug-buzz",
    "dragon-pulse",
    "dragon-rush",
    "power-gem",
    "drain-punch",
    "vacuum-wave",
    "focus-blast",
    "energy-ball",
    "brave-bird",
    "earth-power",
    "switcheroo",
    "giga-impact",
    "nasty-plot",
    "bullet-punch",
    "avalanche",
    "ice-shard",
    "shadow-claw",
    "thunder-fang",
    "ice-fang",
    "fire-fang",
    "shadow-sneak",
    "mud-bomb",
    "psycho-cut",
    "zen-headbutt",
    "mirror-shot",
    "flash-cannon",
    "rock-climb",
    "defog",
    "trick-room",
    "draco-meteor",
    "discharge",
    "lava-plume",
    "leaf-storm",
    "power-whip",
    "rock-wrecker",
    "cross-poison",
    "gunk-shot",
    "iron-head",
    "magnet-bomb",
    "stone-edge",
    "captivate",
    "stealth-rock",
    "grass-knot",
    "chatter",
    "judgment",
    "bug-bite",
    "charge-beam",
    "wood-hammer",
    "aqua-jet",
    "attack-order",
    "defend-order",
    "heal-order",
    "head-smash",
    "double-hit",
    "roar-of-time",
    "spacial-rend",
    "lunar-dance",
    "crush-grip",
    "magma-storm",
    "dark-void",
    "seed-flare",
    "ominous-wind",
    "shadow-force",
    "hone-claws",
    "wide-guard",
    "guard-split",
    "power-split",
    "wonder-room",
    "psyshock",
    "venoshock",
    "autotomize",
    "rage-powder",
    "telekinesis",
    "magic-room",
    "smack-down",
    "storm-throw",
    "flame-burst",
    "sludge-wave",
    "quiver-dance",
    "heavy-slam",
    "synchronoise",
    "electro-ball",
    "soak",
    "flame-charge",
    "coil",
    "low-sweep",
    "acid-spray",
    "foul-play",
    "simple-beam",
    "entrainment",
    "after-you",
    "round",
    "echoed-voice",
    "chip-away",
    "clear-smog",
    "stored-power",
    "quick-guard",
    "ally-switch",
    "scald",
    "shell-smash",
    "heal-pulse",
    "hex",
    "sky-drop",
    "shift-gear",
    "circle-throw",
    "incinerate",
    "quash",
    "acrobatics",
    "reflect-type",
    "retaliate",
    "final-gambit",
    "bestow",
    "inferno",
    "water-pledge",
    "fire-pledge",
    "grass-pledge",
    "volt-switch",
    "struggle-bug",
    "bulldoze",
    "frost-breath",
    "dragon-tail",
    "work-up",
    "electroweb",
    "wild-charge",
    "drill-run",
    "dual-chop",
    "heart-stamp",
    "horn-leech",
    "sacred-sword",
    "razor-shell",
    "heat-crash",
    "leaf-tornado",
    "steamroller",
    "cotton-guard",
    "night-daze",
    "psystrike",
    "tail-slap",
    "hurricane",
    "head-charge",
    "gear-grind",
    "searing-shot",
    "techno-blast",
    "relic-song",
    "secret-sword",
    "glaciate",
    "bolt-strike",
    "blue-flare",
    "fiery-dance",
    "freeze-shock",
    "ice-burn",
    "snarl",
    "icicle-crash",
    "v-create",
    "fusion-flare",
    "fusion-bolt",
    "flying-press",
    "mat-block",
    "belch",
    "rototiller",
    "sticky-web",
    "fell-stinger",
    "phantom-force",
    "trick-or-treat",
    "noble-roar",
    "ion-deluge",
    "parabolic-charge",
    "forests-curse",
    "petal-blizzard",
    "freeze-dry",
    "disarming-voice",
    "parting-shot",
    "topsy-turvy",
    "draining-kiss",
    "crafty-shield",
    "flower-shield",
    "grassy-terrain",
    "misty-terrain",
    "electrify",
    "play-rough",
    "fairy-wind",
    "moonblast",
    "boomburst",
    "fairy-lock",
    "kings-shield",
    "play-nice",
    "confide",
    "diamond-storm",
    "steam-eruption",
    "hyperspace-hole",
    "water-shuriken",
    "mystical-fire",
    "spiky-shield",
    "aromatic-mist",
    "eerie-impulse",
    "venom-drench",
    "powder",
    "geomancy",
    "magnetic-flux",
    "happy-hour",
    "electric-terrain",
    "dazzling-gleam",
    "celebrate",
    "hold-hands",
    "baby-doll-eyes",
    "nuzzle",
    "hold-back",
    "infestation",
    "power-up-punch",
    "oblivion-wing",
    "thousand-arrows",
    "thousand-waves",
    "lands-wrath",
    "light-of-ruin",
    "origin-pulse",
    "precipice-blades",
    "dragon-ascent",
    "hyperspace-fury",
    "breakneck-blitz--physical",
    "breakneck-blitz--special",
    "all-out-pummeling--physical",
    "all-out-pummeling--special",
    "supersonic-skystrike--physical",
    "supersonic-skystrike--special",
    "acid-downpour--physical",
    "acid-downpour--special",
    "tectonic-rage--physical",
    "tectonic-rage--special",
    "continental-crush--physical",
    "continental-crush--special",
    "savage-spin-out--physical",
    "savage-spin-out--special",
    "never-ending-nightmare--physical",
    "never-ending-nightmare--special",
    "corkscrew-crash--physical",
    "corkscrew-crash--special",
    "inferno-overdrive--physical",
    "inferno-overdrive--special",
    "hydro-vortex--physical",
    "hydro-vortex--special",
    "bloom-doom--physical",
    "bloom-doom--special",
    "gigavolt-havoc--physical",
    "gigavolt-havoc--special",
    "shattered-psyche--physical",
    "shattered-psyche--special",
    "subzero-slammer--physical",
    "subzero-slammer--special",
    "devastating-drake--physical",
    "devastating-drake--special",
    "black-hole-eclipse--physical",
    "black-hole-eclipse--special",
    "twinkle-tackle--physical",
    "twinkle-tackle--special",
    "catastropika",
    "shore-up",
    "first-impression",
    "baneful-bunker",
    "spirit-shackle",
    "darkest-lariat",
    "sparkling-aria",
    "ice-hammer",
    "floral-healing",
    "high-horsepower",
    "strength-sap",
    "solar-blade",
    "leafage",
    "spotlight",
    "toxic-thread",
    "laser-focus",
    "gear-up",
    "throat-chop",
    "pollen-puff",
    "anchor-shot",
    "psychic-terrain",
    "lunge",
    "fire-lash",
    "power-trip",
    "burn-up",
    "speed-swap",
    "smart-strike",
    "purify",
    "revelation-dance",
    "core-enforcer",
    "trop-kick",
    "instruct",
    "beak-blast",
    "clanging-scales",
    "dragon-hammer",
    "brutal-swing",
    "aurora-veil",
    "sinister-arrow-raid",
    "malicious-moonsault",
    "oceanic-operetta",
    "guardian-of-alola",
    "soul-stealing-7-star-strike",
    "stoked-sparksurfer",
    "pulverizing-pancake",
    "extreme-evoboost",
    "genesis-supernova",
    "shell-trap",
    "fleur-cannon",
    "psychic-fangs",
    "stomping-tantrum",
    "shadow-bone",
    "accelerock",
    "liquidation",
    "prismatic-laser",
    "spectral-thief",
    "sunsteel-strike",
    "moongeist-beam",
    "tearful-look",
    "zing-zap",
    "natures-madness",
    "multi-attack",
    "10-000-000-volt-thunderbolt",
    "mind-blown",
    "plasma-fists",
    "photon-geyser",
    "light-that-burns-the-sky",
    "searing-sunraze-smash",
    "menacing-moonraze-maelstrom",
    "lets-snuggle-forever",
    "splintered-stormshards",
    "clangorous-soulblaze",
    "zippy-zap",
    "splishy-splash",
    "floaty-fall",
    "pika-papow",
    "bouncy-bubble",
    "buzzy-buzz",
    "sizzly-slide",
    "glitzy-glow",
    "baddy-bad",
    "sappy-seed",
    "freezy-frost",
    "sparkly-swirl",
    "veevee-volley",
    "double-iron-bash",
    "max-guard",
    "dynamax-cannon",
    "snipe-shot",
    "jaw-lock",
    "stuff-cheeks",
    "no-retreat",
    "tar-shot",
    "magic-powder",
    "dragon-darts",
    "teatime",
    "octolock",
    "bolt-beak",
    "fishious-rend",
    "court-change",
    "max-flare",
    "max-flutterby",
    "max-lightning",
    "max-strike",
    "max-knuckle",
    "max-phantasm",
    "max-hailstorm",
    "max-ooze",
    "max-geyser",
    "max-airstream",
    "max-starfall",
    "max-wyrmwind",
    "max-mindstorm",
    "max-rockfall",
    "max-quake",
    "max-darkness",
    "max-overgrowth",
    "max-steelspike",
    "clangorous-soul",
    "body-press",
    "decorate",
    "drum-beating",
    "snap-trap",
    "pyro-ball",
    "behemoth-blade",
    "behemoth-bash",
    "aura-wheel",
    "breaking-swipe",
    "overdrive",
    "apple-acid",
    "grav-apple",
    "spirit-break",
    "strange-steam",
    "life-dew",
    "obstruct",
    "false-surrender",
    "meteor-assault",
    "eternabeam",
    "steel-beam",
    "shadow-rush",
    "shadow-blast",
    "shadow-blitz",
    "shadow-bolt",
    "shadow-break",
    "shadow-chill",
    "shadow-end",
    "shadow-fire",
    "shadow-rave",
    "shadow-storm",
    "shadow-wave",
    "shadow-down",
    "shadow-half",
    "shadow-hold",
    "shadow-mist",
    "shadow-panic",
    "shadow-shed",
    "shadow-sky"
]

export default MoveList;