const Quotes = [
    "Developed by Arceus",
    "The Pokemon Web App, you've been waiting for...",
    "OnlyPokeFans",
    "Pokemon Masters Only",
    "Folagor",
    "Approved by Mew",
    "Walking Furret",
    "DELELELELELEWOOOOOOOOP",
    "Better than Bulbapedia",
    "Giygogagohgwooh",
    "My love for my friends permeates every cell of my body -N",
    "We do have a lot in common.",
    "That’s okay, Brock, you’ll find lots of other girls to reject you!",
    "Smell ya later!",
    "It's Ya Boy Guzma",
    "Bidoof likes it",
    "I came when I heard you had beaten the Elite Four!",
    "Pika pikaaa",
    "It's been 3,000 years... -AZ",
    "What is this pressure I feel.....? Something...... is enraged?",
    "...",
    "...Erm, what was his name now?",
    "Gotta catch 'em all",
    "Ha ha! This gym is great! It is full of women!",
    "4th Gen Remakes pls",
    "I like shorts! They're comfy and easy to wear!",
    "Today, we're dancing for no reason. Someday, we'll disappear for no reason.",
    "Strong Pokémon. Weak Pokémon. ",
    "Change your perspective, and the reality changes.",
    "It’s like my Rattata is in the top percentage of all Rattata.",
    "Where are you from? YES",
    "Are you a boy or a girl?!",
    "Never give up! To do so is an insult to the Pokémon who fight for you!",
    "Having everything go your way is the road to disillusionment.",
    "PEEKO MY DARLING",
    "THUD!!!!!!",
    "Oak's words echoed, There's a time and place for everything. But not now.",
    "That dream… Make it come true! Make your wonderful dream a reality, and it will become your truth!",
    "NO MORE CHARIZARD PLEASE",
    "I see now that one’s birth is irrelevant. It’s what you do that determines who you are.",
    "You see, sometimes friends have to go away, but a part of them stays behind with you.",
    "Me give up? No way!",
    "ZZZZZZZZZZZZZZZZZZ",
    "Developed by Rodrigo Ramirez",
    "I ran out of cool phrases",
    "Marry me, Cynthia",
    "Did you know..."
];

export default Quotes;